.node__root > circle {
  r: 5;
}

.node__branch > circle {
  r: 5;
}

.node__leaf > circle {
  r: 5;
}

.node__link {
  stroke: #216d09;
  stroke-width: 0.2rem;
}


.circle-with-child {
  position: absolute;
  color: white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  /* line-height: 100px; */
  text-align: center;
  font-size: 18px;
  border: 2px solid #4c51bf;

  margin-top: -2rem;
  margin-left: 5.25rem;
  /* background-color: red; */
}

.circle-issue {
  position: absolute;
  color: white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  /* line-height: 100px; */
  text-align: center;
  font-size: 18px;
  border: 2px solid #666;
  margin-top: 0.25rem;
  margin-left: 5.25rem;
}

.circle_no_child {
  position: absolute;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  /* line-height: 100px; */
  text-align: center;
  font-size: 18px;
  border: 2px solid #4c51bf;
  margin-top: 0.25rem;
  /* margin-bottom: -50px; */
  margin-left: 5.27rem;
  /* background-color: red; */
  color: white;
}
/* 

                //   textAlign: 'center',
                //   backgroundColor: '#bbb',
                //   borderRadius: '50%',
                //   display: 'inline-block',
                //   border: '2px solid #666' */






                /* Style for dropdown button */
.dropbtn {
  background-color: #5d5fd6;
  color: white;
  padding: 10px;
  font-size: 15px;
  border: none;
  cursor: pointer;
}

/* Style for dropdown content */
.dropdown-content {
  display: none; /* Hidden by default */
  position: absolute;
  background-color: #ffffff;
  min-width: 90px;
  z-index: 1;
}

/* Style for each content div */
.dropdown-content div {
  padding: 5px;
}

/* Highlight dropdown options on hover */
.dropdown-content div:hover {
  background-color: #eaeaea;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Highlight dropdown button when dropdown menu is open */
.dropdown:hover .dropbtn {
  background-color: #474885;
}
